import React from "react"
import { Link } from "gatsby"
import classnames from "classnames"
import { useAppDispatch, useAppSelector } from "@/redux/provider"
import { amountWithCommas } from "@/utils"
import * as style from "./style.module.scss"
import Emoji from "@/components/common/Emoji"

const Menu = () => {
  const remoteModel = useAppSelector((state) => state.account.remoteModel)

  return (
    <div className={style.menu}>
      <div className={style.menuInner}>
        <Link to="/power" className={style.link} activeClassName={style.linkActive}>
          <div className={style.linkInner}>
            <Emoji type="zap" size={32} />
            <span className={style.linkText}>Power</span>
          </div>
        </Link>
        <Link to="/armory" className={style.link} activeClassName={style.linkActive}>
          <div className={style.linkInner}>
            <Emoji type="money-bag" size={32} />
            <span className={style.linkText}>Armory</span>
          </div>
        </Link>
        <Link to="/" className={classnames(style.link, style.linkGames)} activeClassName={style.linkActive}>
          <div className={style.linkInner}>
            <Emoji type="game" size={32} />
            <span className={style.linkText}>Games</span>
          </div>
        </Link>
        <Link to="/referrals" className={style.link} activeClassName={style.linkActive}>
          <div className={style.linkInner}>
            <div className={style.count}>{amountWithCommas(remoteModel?.refsCount || 0)}</div>
            <Emoji type="pig-face" size={32} />
            <span className={style.linkText}>Referrals</span>
          </div>
        </Link>
        <Link to="/account" className={style.link} activeClassName={style.linkActive}>
          <div className={style.linkInner}>
            <Emoji type="winner" size={32} />
            <span className={style.linkText}>Account</span>
          </div>
        </Link>
      </div>
    </div>
  )
}

export default Menu

import React, { useEffect, useState } from "react"
import { Modal, Button, Tabs, message, notification } from "antd"
import { backButton } from "@telegram-apps/sdk-react"
import { useAppDispatch, useAppSelector } from "@/redux/provider"
import { AppActions, AppTypes } from "@/redux/app"
import { unlockPig } from "@/services/api"
import * as style from "./style.module.scss"
import { AccountActions } from "@/redux/account"

const ModalBoost = () => {
  const dispatch = useAppDispatch()
  const remoteModel = useAppSelector((state) => state.account.remoteModel)
  const modalUnlock = useAppSelector((state) => state.app.modalUnlock)
  const launchParams = useAppSelector((state) => state.app.launchParams)
  const pigToUnlock = remoteModel?.pigz[modalUnlock.id]
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (modalUnlock.opened) {
      backButton.mount() // enable back button
      backButton.show()
      return backButton.onClick(closeModal)
    }
    backButton.hide()
  }, [modalUnlock.opened])

  const closeModal = () => {
    dispatch(AppActions.MODAL_UNLOCK_SET(false, -1))
  }

  const unlock = async () => {
    setLoading(true)
    const result = await unlockPig(launchParams?.initDataRaw!, pigToUnlock?.id!)
    dispatch(AccountActions.ACCOUNT_INFO_UPDATE_SAGA())
    setTimeout(() => {
      if (result.error) {
        notification.error({
          message: "ERROR :(",
          description: "Unlock failed. Try again later.",
          placement: "top",
          duration: 20,
        })
      } else {
        notification.success({
          message: "CONGRATULATIONS!",
          description: "New pig successfully unlocked!",
          placement: "top",
          duration: 20,
        })
      }
      setLoading(false)
      closeModal()
    }, 1000)
  }

  return (
    <Modal
      title={null}
      open={modalUnlock.opened}
      onCancel={() => closeModal()}
      footer={null}
      width={420}
      closeIcon={<span className="xi xi-close" />}
      destroyOnClose
    >
      <Tabs items={[{ key: "account", label: "Unlock Character" }]} />
      {pigToUnlock && (
        <div className="text-center">
          <h2 className="mb-0">{pigToUnlock?.name}</h2>
          <p className="text-muted mb-3">He's a good pig</p>
          <div className="mb-2">
            <img src={`/pigz/pics/${pigToUnlock.id}.svg`} alt={pigToUnlock.name} style={{ maxWidth: "330px" }} />
          </div>
          <div className="mb-2">
            <Button loading={loading} size="large" type="primary" onClick={() => unlock()}>
              <i className="xi xi-unlock" /> Unlock
            </Button>
          </div>
          <p className="text-muted font-size-14 mb-5">
            One XDIAMOND will be spent
            <br />
            Balance: {remoteModel?.xdiamond || 0} XDIAMOND
          </p>
        </div>
      )}
    </Modal>
  )
}

export default ModalBoost
